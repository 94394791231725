import React, { Component } from 'react'
import { BrowserRouter as Router } from "react-router-dom";
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBMask,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBView,
  MDBContainer,
  MDBFormInline,
  MDBAnimation,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBIcon,
  MDBTypography ,
} from "mdbreact";
import {
    MDBJumbotron
  } from "mdbreact";
import "../index.css";
import logo from '../Pages/img/logo.png'
import logo2 from '../Pages/img/logo2.jpg'
import { Alert, Form, Input, Button, Select, Icon, message, Tag, Badge, Tooltip , Divider } from 'antd';
export default class Header2 extends Component {
  

      constructor(props) {
        super(props);
        this.state = {
          isOpen: false,
          nameuser: '',
          admin_type: '1',
          Username: '',
          collapse: false,
          isWideEnough: true,
          collapsed: false
        };
        this.handleTogglerClick = this.handleTogglerClick.bind(this);
      }
    
      handleTogglerClick = () => {
        this.setState({
          collapsed: !this.state.collapsed
        });
      };

      logout() {
           localStorage.removeItem(('id_member'))
           localStorage.removeItem(('name_member'))
           localStorage.removeItem(('admin_type'))
        //  localStorage.clear();
      
          setTimeout(() => {
            window.location.href = '/'
          //  window.location.href = 'https://e-student.kpru.ac.th/vaccinekpru'
          }, 1000);
         // this.setState({ admin_type: 'public' }
          message.success("ออกจากระบบแล้ว")
        }


      componentDidMount() {
        //  const token = JSON.parse(localStorage.getItem('nameuser'));
          if (localStorage.getItem('name') !== "") {
            this.setState({ nameuser: localStorage.getItem('name') })
            this.setState({ admin_type: localStorage.getItem('admin_type') })
          } else {
      
              this.setState({ admin_type: 'public' })
  
          }
  
           // this.setState({ nameuser: localStorage.getItem('nameuser') })
           // this.setState({ admin_type: localStorage.getItem('admin_type') })
        }



  render() {

    console.log(this.state.admin_type) ;

    const overlay = (
        <div
          id="sidenav-overlay"
          style={{ backgroundColor: "pink" }}
          onClick={this.handleTogglerClick}
        />
      );


    let publics
      publics = (
        <>
          <MDBNavItem  >
            <MDBNavLink to="/">   หน้าหลัก </MDBNavLink>
          </MDBNavItem>
        
            {/* <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle tag='a' className='nav-link'>
                หน่วยงาน
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                    <MDBNavLink to="/Medical"> <MDBDropdownItem ><MDBIcon fas icon="ambulance" /> กลุ่มงานการแพทย์</MDBDropdownItem></MDBNavLink>
                    <MDBNavLink to="/Manage">  <MDBDropdownItem ><MDBIcon fas icon="ambulance" /> กลุ่มงานฝ่ายบริหาร</MDBDropdownItem></MDBNavLink>
                    <MDBNavLink to="/Nursing"> <MDBDropdownItem ><MDBIcon fas icon="ambulance" /> กลุ่มงานการพยาบาล</MDBDropdownItem></MDBNavLink>
                    <MDBNavLink to="/Pharmacy">  <MDBDropdownItem ><MDBIcon fas icon="ambulance" /> กลุ่มงานเภสัชกรรม</MDBDropdownItem></MDBNavLink>
                    <MDBNavLink to="/Dental">  <MDBDropdownItem ><MDBIcon fas icon="ambulance" /> กลุ่มงานทันตกรรม</MDBDropdownItem></MDBNavLink>
                    <MDBNavLink to="/Health">  <MDBDropdownItem ><MDBIcon fas icon="ambulance" /> กลุ่มงานการประกันสุขภาพ</MDBDropdownItem></MDBNavLink>
                    <MDBNavLink to="/Primary">  <MDBDropdownItem ><MDBIcon fas icon="ambulance" /> กลุ่มงานบริการด้านปฐมภูมิและองค์รวม</MDBDropdownItem></MDBNavLink>
                    <MDBNavLink to="/Sanitary">  <MDBDropdownItem ><MDBIcon fas icon="ambulance" /> กลุ่มงานสุขาภิบาล</MDBDropdownItem></MDBNavLink>
                    <MDBNavLink to="/Identify">  <MDBDropdownItem ><MDBIcon fas icon="ambulance" /> กลุ่มงานชันสูตร</MDBDropdownItem></MDBNavLink>
                    <MDBNavLink to="/Radiology">  <MDBDropdownItem ><MDBIcon fas icon="ambulance" /> กลุ่มงานรังสีวิทยา</MDBDropdownItem></MDBNavLink>

                  
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem> */}
            {/* <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle tag='a' className='nav-link'>
                เกี่ยวกับองค์กร
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                    <MDBNavLink to="/History"> <MDBDropdownItem >ประวัติของโรงพยาบาล</MDBDropdownItem></MDBNavLink>
                    <MDBNavLink to="/Vision">  <MDBDropdownItem >วิสัยทัศน์/ค่านิยม/พันธกิจ</MDBDropdownItem></MDBNavLink>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem> */}
          {/* <MDBNavItem  >
            <MDBNavLink to="/">   เกี่ยวกับองค์กร </MDBNavLink>
          </MDBNavItem> */}
          {/* <MDBNavItem  >
            <MDBNavLink to="/">   สำหรับประชาชน </MDBNavLink>
          </MDBNavItem> */}
          
          {/* <MDBNavItem  >
            <MDBNavLink to="/">   ตารางออกตรวจผู้ป่วยนอก </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem  >
            <MDBNavLink to="/">   การศึกษาก่อนปริญญา </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem  >
            <MDBNavLink to="/">   การศึกษาหลังปริญญา </MDBNavLink>
          </MDBNavItem> */}
          <MDBNavItem  >
            <MDBNavLink to="/login">   เข้าสู่ระบบ </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem  >
            <MDBNavLink to="/login2">   เข้าสู่ระบบจัดการ </MDBNavLink>
          </MDBNavItem>
         
          {/* <MDBNavItem  >
            <MDBNavLink to="/main/LoginPages">  เข้าสู่ระบบ </MDBNavLink>
          </MDBNavItem> */}
        
        </>
      )

      let admin
      admin = (
        <>
          {/* <MDBNavItem  >
            <MDBNavLink to="/Addfileme">   จัดการไฟล์ประชาสัมพันธ์ </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem  >
            <MDBNavLink to="/Addfileprocurement">   จัดการไฟล์จัดซื้อจัดจ้าง/ita </MDBNavLink>
          </MDBNavItem> */}
          <MDBNavItem  >
            <MDBNavLink to="/Addbanner">   จัดการแบนเนอร์ </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem  >
            <MDBNavLink to="/Addcategory">   จัดการภาพผลงาน </MDBNavLink>
          </MDBNavItem>
          {/* <MDBNavItem  >
            <MDBNavLink to="/Addcar">   จัดการรถ/คนขับ </MDBNavLink>
          </MDBNavItem> */}
       
          <MDBNavItem onClick={this.logout} >
              <MDBNavLink to="/">  ออกจากระบบ </MDBNavLink>
            </MDBNavItem>
         
          {/* <MDBNavItem  >
            <MDBNavLink to="/main/LoginPages">  เข้าสู่ระบบ </MDBNavLink>
          </MDBNavItem> */}
        
        </>
      )

      let adminmange
      adminmange = (
        <>
          {/* <MDBNavItem  >
            <MDBNavLink to="/Addfileme">   จัดการไฟล์ประชาสัมพันธ์ </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem  >
            <MDBNavLink to="/Addfileprocurement">   จัดการไฟล์จัดซื้อจัดจ้าง/ita </MDBNavLink>
          </MDBNavItem> */}
          <MDBNavItem  >
            <MDBNavLink to="/Managehome">   หน้าแรก </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem  >
            <MDBNavLink to="/Showgmmember">   จัดการสมาชิก </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem  >
            <MDBNavLink to="/Showcarparts">   จัดการอะไหล่ </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem  >
            <MDBNavLink to="/ManagehomeReport">   รายงานสรุป </MDBNavLink>
          </MDBNavItem>
          {/* <MDBNavItem  >
            <MDBNavLink to="/Addcar">   จัดการรถ/คนขับ </MDBNavLink>
          </MDBNavItem> */}
       
          <MDBNavItem onClick={this.logout} >
              <MDBNavLink to="/">  ออกจากระบบ </MDBNavLink>
            </MDBNavItem>
         
          {/* <MDBNavItem  >
            <MDBNavLink to="/main/LoginPages">  เข้าสู่ระบบ </MDBNavLink>
          </MDBNavItem> */}
        
        </>
      )

      let position
      position = (
        <>
          {/* <MDBNavItem  >
            <MDBNavLink to="/Addfileprocurement">   จัดการไฟล์จัดซื้อจัดจ้าง </MDBNavLink>
          </MDBNavItem> */}
         
       
          <MDBNavItem onClick={this.logout} >
              <MDBNavLink to="/">  ออกจากระบบ </MDBNavLink>
            </MDBNavItem>
         
          {/* <MDBNavItem  >
            <MDBNavLink to="/main/LoginPages">  เข้าสู่ระบบ </MDBNavLink>
          </MDBNavItem> */}
        
        </>
      )


    return (
        <div>
 {/* transparent */}
        <MDBNavbar
          color="danger-color-dark lighten-2"
          dark
          expand="md"
          fixed="top"
          scrolling
        >
          <MDBContainer >
            <MDBNavbarBrand>
              <strong className="white-text"><img
                  src={logo2}
                  width={30} height={30}
                  alt="Third slide"
                />{this.state.collapsed === false ?<></>:<> อู่แบ้งค์รถยก</>} </strong>
            </MDBNavbarBrand>
            <MDBNavbarToggler onClick={this.handleTogglerClick} />
            <MDBCollapse isOpen={this.state.collapsed} navbar>
              <MDBNavbarNav left >
                {/* <MDBNavItem active>
                  <MDBNavLink to="#!">Home</MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink to="#!">Link</MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink to="#!">Profile</MDBNavLink>
                </MDBNavItem> */}
                                     {this.state.admin_type === '2' ? <>
                                      {admin}
                                      </> : null}

                                      {this.state.admin_type === null ? <>
                                      {publics}
                                      </> : null}

                                      {this.state.admin_type === '4' ? <>
                                      {position}
                                      </> : null}

                                      {this.state.admin_type === '9' ? <>
                                      {adminmange}
                                      </> : null}
              </MDBNavbarNav>
              <MDBNavbarNav right>
                <MDBNavItem>
                  <MDBFormInline waves>
                    <div className="md-form my-0">
                      {/* <input
                        className="form-control mr-sm-1"
                        type="text"
                        placeholder="Search"
                        aria-label="Search"
                      /> */}
                    </div>
                  </MDBFormInline>
                </MDBNavItem>
              </MDBNavbarNav>
            </MDBCollapse>
          </MDBContainer>
        </MDBNavbar>
       

        {this.state.collapsed && overlay}
        <div id="apppage" className='mb-2'>
       
       {/* <MDBView  className="p-0 gradien">
         <MDBMask className="d-flex justify-content-center align-items-center  ">
           <MDBContainer className="mt-5 ">
             <MDBRow>
               <MDBCol
                 md="12"
                 className="white-text text-center text-md-left mt-5  mb-5 gradient"
               >
                 <MDBAnimation type="fadeInLeft" delay=".3s">
                    <h2 className="h1 font-weight-bold  red-text">
                 
                   อู่แบ้งค์รถยก
                
                   </h2>
                 
               
                   <hr className="hr-light " />
                   <h3 className='white-text aa'>0650961963</h3>
                 </MDBAnimation>
           
         
               </MDBCol>

              
             </MDBRow>
           </MDBContainer>
       </MDBMask>  
       </MDBView> */}
       </div>
      </div>
    )
  }
}
