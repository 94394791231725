import React, { Component } from 'react'
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBAlert, MDBSelect , MDBInput ,MDBIcon } from 'mdbreact';
import { MDBSelectInput, MDBSelectOptions, MDBSelectOption } from "mdbreact";
import Axios from 'axios'
import { Alert, Form, Input, Button, Select, Icon, message, Tag, Badge, Tooltip  } from 'antd';
import { api } from '../../api';
import Swal from 'sweetalert2'

export default class Modaleditcarparts3 extends Component {
    constructor(props) {
        super(props);
this.state = {
  modal: false,

   inside:"",
   faculty:"",
   Expert:[],
   idExpert:"",
   id_send:"",
   send_comment1:"",
   send_comment4:"",


 

   id_carpartlist:'',
   carparts_pricereceipt:0,
   carparts_num:0,

}

this.handleChange = this.handleChange.bind(this);
this.handleChange2 = this.handleChange2.bind(this);
this.handleChange3 = this.handleChange3.bind(this);
this.handleSubmit = this.handleSubmit.bind(this);
this.toggle = this.toggle.bind(this);
this.handleChange4 = this.handleChange4.bind(this);
this.carpartslistedit = this.carpartslistedit.bind(this);
this.onTextChange = this.onTextChange.bind(this);
}


toggle = () => {
  this.setState({
    modal: !this.state.modal
  });
 // this.props.selectatf();
 this.carpartslistedit();
 this.setState({ id_carpartlist:this.props.id_carpartlist})
}

onTextChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
}

 componentDidMount() {
      const { id_carpartlist } = this.props.id_carpartlist
      console.log(id_carpartlist);
      this.setState({ id_carpartlist:this.props.id_carpartlist})
    //   setTimeout(() => {
    //     //ภายใน
    //      Axios.get('https://conference.kpru.ac.th/PHPAPI/selectExpert.php')
    //     .then(res => {
    //       console.log(res);
    //     //  this.setState({ bankname: res.data, loading: false })
    //       this.setState({ Expert: res.data})
    //     }) 

    //   }, 0);

  }


  carpartslistedit() {
     setTimeout(() => {
        //ภายใน
        api.get('selectcarpartslistedit.php?id='+this.props.id_carpartlist)
        .then(res => {
          console.log(res.data[0].carparts_pricereceipt3);
          this.setState({ carparts_pricereceipt: res.data[0].carparts_pricereceipt3})
          this.setState({ carparts_num: res.data[0].carparts_num})
        //  this.setState({ bankname: res.data, loading: false })
         // this.setState({ getadmin: res.data,loading:false})
        }) 
      }, 0);

}


  handleChange(event) {    this.setState({filecomment1: event.target.files[0]});  }
  handleChange3(event) {    this.setState({filecomment2: event.target.files[0]});  }
  handleChange2(event) {    this.setState({send_comment1: event.target.value});  }
  handleChange4(event) {    this.setState({send_comment4: event.target.value});  }


     handleSubmit() {
       
         // alert('A name was submitted: ' + this.state.value);
        // const date = new Date().toISOString().slice(0, 10)
        setTimeout(() => {
           const datafrm = new FormData(); //สร้างฟอร์มสำหรับการส่งข้อมูล
           datafrm.append("id_carpartlist", this.state.id_carpartlist);
           datafrm.append("carparts_pricereceipt3", this.state.carparts_pricereceipt);
           datafrm.append("carparts_num", this.state.carparts_num);
        
           api.post("updatecarpartslist3.php",datafrm,{
                 headers: {
                     'content-type': 'multipart/form-data'
                 }
             })
     
        .then(res => {
         if (res.status=== 200) {
             Swal.fire({
                 title: 'บันทึกข้อมูลสำเร็จ',
                 icon: 'success',
               // showCancelButton: true,
                 confirmButtonText: 'ตกลง',
               // cancelButtonText: 'No, keep it'
               }).then((result) => {
                 this.toggle();  
            //   this.getcarpartlist();
                 this.props.getcarpartlist();
               })
         }else{
           message.error("บันทึกข้อมูลไม่สำเร็จ!")
         }
     
       })
     }, 2000);
     
 
     //window.location.href = '/adminfaculty'
     //this.props.updateat();
     //this.getExpert()
      
       }

  

    render() {
        
        return (
            <>
            <MDBBtn size='sm' onClick={this.toggle}>แก้ไข</MDBBtn>

            <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
              <MDBModalHeader toggle={this.toggle}>แก้ไขรายการ </MDBModalHeader>
              <MDBModalBody>
                <h3 className='text-center'>{this.props.carparts_name}</h3>
                 <div className="mb-2 text-left">
                     <label className="font-weight-bold text-left"><MDBIcon icon="bible" /> ราคา</label>
                     <input className="form-control" onChange={this.onTextChange} name="carparts_pricereceipt"
                       value={this.state.carparts_pricereceipt} placeholder="ราคา" required />
                  </div>
                 <div className="mb-2 text-left">
                      <label className="font-weight-bold text-left"><MDBIcon icon="bible" /> จำนวน</label>
                      <input className="form-control" onChange={this.onTextChange} name="carparts_num"
                        value={this.state.carparts_num} placeholder="จำนวน" required disabled />
                 </div>
              {/* <p>ราคา {this.state.idExpert}</p>    */}
             
              </MDBModalBody>
              <MDBModalFooter>
                
                <MDBBtn color="primary" size='sm' onClick={this.handleSubmit} >บันทึก</MDBBtn>
                <MDBBtn color="secondary" size='sm' onClick={this.toggle}>ปิด</MDBBtn>
              </MDBModalFooter>
            </MDBModal>
          </>
          
        )
    }
}
