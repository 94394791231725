import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBInput, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCarousel,
    MDBCarouselInner,
    MDBCarouselItem } from 'mdbreact';
import { MDBTable, MDBTableBody, MDBTableHead, MDBDataTable } from 'mdbreact';

import { api } from '../../api';
import Modaladdquotation from './Modaladdquotation';
import LoadingOverlay from 'react-loading-overlay';
import Modaladdquotationlist from './Modaladdquotationlist';
import Modaladdquotationlist2 from './Modaladdquotationlist2';
import Modaladdquotationlist3 from './Modaladdquotationlist3';






export default class ManagehomeReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:false,
      emp:[],
      news:[],
      banner:[],
      date1:'',
      date2:'',
    };
   this.getbanner = this.getbanner.bind(this);
   this.delete = this.delete.bind(this);
   this.print = this.print.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
    this.getbanner2 = this.getbanner2.bind(this);
  }

  componentDidMount() {
  this.getbanner();
   
  }

  getbanner() {
    this.setState({ loading: true})
    setTimeout(() => {
       //ภายใน
       api.get('selectquotation.php')
       .then(res => {
         console.log(res);
       //  this.setState({ bankname: res.data, loading: false })
         this.setState({ banner: res.data,loading:false})
       }) 
     }, 0);
  }

  getbanner2() {
    this.setState({ loading: true})
    setTimeout(() => {
       //ภายใน
       api.get('selectquotationreport.php?date1='+this.state.date1+'&date2='+this.state.date2)
       .then(res => {
         console.log(res);
       //  this.setState({ bankname: res.data, loading: false })
         this.setState({ banner: res.data,loading:false})
       }) 
     }, 0);
  }

  

  delete = (id) => { //เมื่อมีการคลิกลบ ฟังก์ชั่นนี้จะถูกเรียกใช้งาน แล้วส่ง id ที่ต้องการลบไปที่ไฟล์ delete.php
    api.get('deletequotation.php?id=' + id)
        .then((res) => {
            this.getbanner()
        })
  }

  print = (id) => { //เมื่อมีการคลิกลบ ฟังก์ชั่นนี้จะถูกเรียกใช้งาน แล้วส่ง id ที่ต้องการลบไปที่ไฟล์ delete.php
    // api.get('deletequotation.php?id=' + id)
    //     .then((res) => {
    //         this.getbanner()
    //     })
   // window.open('http://localhost/cradlebankbackend/TCPDF-main/slfrom.php?id'+id+'', '_blank');
    window.open('http://localhost/cradlebankbackend/TCPDF-main/slfrom.php?id'+id+'', '_blank');
  }


  onTextChange = (e) => {
    console.log(e.target.value);
    this.setState({ [e.target.name]: e.target.value })
}

render() {
console.log(this.state.news)

const data = {
  columns: [
    {
      label: 'ลำดับ',
      field: 'list',
      sort: 'list',
      width: 150
    },
    {
        label: 'รายการ',
        field: 'PIC',
        sort: 'PIC',
        width: 200
      },
    {
      label: 'ชื่อสมาชิก',
      field: 'NAME',
      sort: 'NAME',
      width: 200
    },
     {
      label: 'เบอร์โทร',
      field: 'DATES',
      sort: 'DATES',
      width: 200
    },
    {
      label: 'ทะเบียน',
      field: 'DATESTB',
      sort: 'DATESTB',
      width: 200
    },
    {
      label: 'พิมพ์',
      field: 'FACULTY',
      sort: 'FACULTY',
      width: 100
    }


  ],

  rows: [...this.state.banner.map((data, i) => (
    {
       list: <>{i+1}</>,
       PIC: <> ใบเสนอราคา</>,
       NAME: <>{data.gm_name}</>,
       DATES: <>{data.gm_tel}</>,
       DATESTB: <>{data.gm_carregistration}</>,
       FACULTY: <> 
      <Modaladdquotationlist quotation_id={data.quotation_id}  />
      <Modaladdquotationlist2 quotation_id={data.quotation_id}  />
      <Modaladdquotationlist3 quotation_id={data.quotation_id}  />
       </>,
     
      

      //  random: <h5 searchvalue={data.Curriculumopen_TEAM} >tourist <span className="badge badge-danger">narrow</span></h5>,

    }
   ))] 

};

const persons = <MDBDataTable
// searching ={true}
searchLabel="ค้นหา รายการ"
striped
bordered
small 
entries={20}
// entriesOptions={["10", "20"]}
order={["age", "desc"]}
data={data}
entriesLabel="จำนวนที่แสดง "
infoLabel= {["แสดง", "ถึง", "จากทั้งหมด", "รายการ"]}
paginationLabel={["ย้อนกลับ", "ถัดไป"]}
responsive 
sortRows={['random']}
/>

return (
  <LoadingOverlay
  active={this.state.loading}
  spinner
  text='Loading your data...'
  >
  <MDBContainer fluid>
    <br/>
    <h2 className='text-center mt-2'>ระบบจัดการ รายการซ่อม</h2>
    <h4 className='text-center'>ผู้ใช้งาน : {localStorage.getItem('name_member')}</h4>
    {/* <p className='text-center'><Modaladdquotation getbanner={this.getbanner}/></p>  */}
    <br/>
<MDBRow>
<MDBCol sm="2">  </MDBCol>
<MDBCol sm="4">
<div className="mb-2 text-left">
                                                    <label className="font-weight-bold text-left"><MDBIcon icon="date" /> วันที่เริ่มต้น</label>
                                                    <input className="form-control" type='date' onChange={this.onTextChange} name="date1"
                                                        value={this.state.date1} placeholder="วันที่เริ่ม" required />
                                                </div>

</MDBCol>
<MDBCol sm="4">

<div className="mb-2 text-left">
                                                    <label className="font-weight-bold text-left"><MDBIcon icon="date" /> วันที่สิ้นสุด</label>
                                                    <input className="form-control" type='date' onChange={this.onTextChange} name="date2"
                                                        value={this.state.date2} placeholder="วันที่สิ้นสุด" required />
                                                </div>
</MDBCol>
<MDBCol sm="2">  </MDBCol>


</MDBRow>
<div className='text-center'>
<MDBBtn  color="deep-purple" onClick={this.getbanner2}><MDBIcon far icon="save" /> ค้นหา</MDBBtn> 
    </div>
<hr/>


    <h2 className='text-left'>รายงานใบเสนอราคา</h2>
     {persons}
  </MDBContainer>
  </LoadingOverlay>  
)
}
}